import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import catalogue from '@/store/modules/catalogue'
import module from '@/store/modules/module'
import project from '@/store/modules/project'
import snackbar from '@/store/modules/snackbar'
import chooseOrganization from '@/store/modules/chooseOrganization'
import product from '@/store/modules/product'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    catalogue,
    module,
    project,
    snackbar,
    chooseOrganization,
    product
  }
})
