import ProductService from '@/services/product.service'
import { actionStates } from '@/constants'

import { ProductClass } from '@/classes/product/ProductClass'
import { ProductReleasePlugin } from '@/classes/product/ProductReleasePlugin'

import { getHighestBeVersion } from '@/utils/version'

import Vue from 'vue'

const state = {
  sandBox: null,
  currentState: actionStates.INITIAL
}

const getters = {
  getCurrentState (state) {
    return state.currentState
  },

  getSandBox (state) {
    return state.sandBox
  },

  getShowUpdateView (state) {
    if (state.sandBox && state.sandBox._highestVersion &&
      (state.sandBox._highestVersion.active || state.sandBox._highestVersion.mandatory_users)) {
      return true
    }
    return false
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },


  setSandBox (state, sandBox) {
    state.sandBox = sandBox
  }
}

const actions = {

  async fetchSandBox (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      const sandboxProductResponse = await ProductService.getSandboxProduct()

      const sandboxProduct = new ProductClass(sandboxProductResponse)

      vuexContext.commit('setSandBox', sandboxProduct)

      await vuexContext.dispatch('fetchPluginReleases', vuexContext.state.sandBox.id)

      const revitVersionResponse = await window.jsSandboxInterface.getRevitVersion()

      // const revitVersionResponse = null

      let revitVersion

      if (revitVersionResponse) {
        revitVersion = JSON.parse(revitVersionResponse)

        Vue.set(vuexContext.state.sandBox, '_installedVersion', revitVersion.plugin_version)
        Vue.set(vuexContext.state.sandBox, '_revitYear', revitVersion.revit_version)
      }

      const highestVersion = getHighestBeVersion({
        beVersions: vuexContext.state.sandBox._releases,
        installedVersion: vuexContext.state.sandBox._installedVersion
      })

      if (highestVersion) {
        Vue.set(vuexContext.state.sandBox, '_highestVersion', highestVersion)
      }

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
      console.log(sandboxProduct, 'sandboxProduct')


    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async fetchPluginReleases (vuexContext, productId) {
    try {
      const pluginReleasesResponses = await ProductService.getPluginReleases(productId)
      const pluginReleases = ProductReleasePlugin.createList(pluginReleasesResponses)

      Vue.set(vuexContext.state.sandBox, '_releases', pluginReleases)

    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }

  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
