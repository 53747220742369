<template>
  <div>
    <module-single-overview-top-section
      :module-prop.sync="module"
      @importModuleToRevit="importModuleToRevit"
      @uploadModule="uploadModule"
    />
  </div>
</template>

<script>

import ModuleSingleOverviewTopSection from '@/components/module/single-overview/ModuleSingleOverviewTopSection'

import { mapGetters } from 'vuex'
import VueCookies from 'vue-cookies'

export default {
  name: "ModuleSingleOverviewTopSectionContainer",
  components: {
    ModuleSingleOverviewTopSection
  },

  computed: {
    ...mapGetters(
      {
        module: 'module/getSingleModule',
        userSelectedOrganization: 'user/getUserSelectedOrganization'
      }
    )
  },

  methods: {
    async uploadModule (moduleId) {
      try {
        let token = VueCookies.get('current_user')
        const response = await window.jsSandboxInterface.uploadModuleEvent(moduleId, token, this.userSelectedOrganization.slug)
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Successfully uploaded',
          duration: 4000,
          mode: 'success'
        })
        if (response !== 'Success') {
          throw new Error()
        }
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Failed to upload',
          duration: 4000,
          mode: 'warning'
        })
      }

      try {
        await this.$store.dispatch('module/fetchSingleModule', this.$route.params.id)
      } catch (e) {
        await this.$router.replace('/modules')
      }
    },

    importModuleToRevit () {
      this.$store.dispatch('catalogue/downloadOneCatalogueModule', this.module.id)
    }
  }
}
</script>

<style scoped>

</style>
