import $axios from '@/plugins/axios'

 class ProductService {
  getSandboxProduct () {
    const baseAuthURL = `${ location.protocol }//${ process.env.VUE_APP_API_IMT_BASE_URL }/api/v1`

    return $axios.get(`${ baseAuthURL }/get-sandbox-product`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

   getPluginReleases(productId) {
     const baseAuthURL = `${ location.protocol }//${ process.env.VUE_APP_API_IMT_BASE_URL }/api/v1`

     return $axios.post(`${ baseAuthURL }/get-plugin-releases`, {
       "product_id": productId
     })
       .then((res) => {
         return res.data
       })
       .catch((error) => {
         throw error
       })
   }
}

export default new ProductService()