import { BaseClass } from '@/classes/base/BaseClass'
import { CustomErrorClass } from '@/classes/error/CustomErrorClass'

export class ProductClass extends BaseClass {
  constructor (data = {}) {
    super()

    this.id = data.id
    this.api_domain = data.api_domain
    this.created_at = data.created_at
    this.domain = data.domain

    this.logo = data.logo
    this.name = data.name

    this.updated_at = data.updated_at

    this._releases = null
    this._highestVersion = null

    this._installedVersion = null

    this._revitYear = null
  }

  get versionUrl () {
    if (this._highestVersion && this._highestVersion.software_versions &&
      this._highestVersion.software_versions.revit_windows) {

      const revitWindows = this._highestVersion.software_versions.revit_windows
      const foundVersion = revitWindows.find(item => item.version === this._revitYear)
      if (foundVersion) {
        return foundVersion.file.url
      }
    }
    throw new CustomErrorClass('Can not find version url.Please contact system administrators!')
  }

  get allVersions () {
    if (this._highestVersion && this._highestVersion.software_versions && this._highestVersion.software_versions.revit_windows) {
      return this._highestVersion.software_versions.revit_windows
    }
    return null
  }

  get version () {
    if (this._highestVersion) {
      return this._highestVersion.version
    }
    return null
  }
}