import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: '#D99F00',
          lighten1: '#FFC219',
          lighten2: '#FFF7E0',
          lighten3: '#FFF1C6',
          darken1: '#A37700',
          darken2: '#8A6500'
        },
        anchor: '#D99F00',
        // ---
        info: '#3B86FF',
        warning: '#FFB755',
        error: '#F0445F',
        success: '#3CC480',
        success2: '#3DC480',
        grey: {
          base: '#969696',
          lighten1: '#C3C3C3',
          lighten2: '#F0F0F0',
          lighten3: '#F9F9F9',
          lighten4: '#F7F7F7',
          darken1: '#6F6F6F',
          darken2: '#444444'
        },
        'tag-important': {
          base: '#F0445F'
        },
        'tag-cad': {
          base: '#C9ECDB'
        },
        'tag-document': {
          base: '#FBE9D0'
        }
      }
    }
  }
})
