<template>
  <div>
    <div class="d-flex justify-center align-center flex-column">

      <v-progress-circular
          class="mt-12"
          indeterminate
          color="primary"
          size="52"
          v-if="currentState === actionStateConstants.LOADING"
      ></v-progress-circular>

      <div v-else>

        <div v-if="!isItOldLauncher">
          <transition :key="$router.currentRoute.path" mode="out-in" name="scale">
            <div>
              <div class="text-h3 text--secondary d-flex justify-center">
                Update available!
              </div>

              <div class="text-small text--secondary mt-4">
                Version {{ sandbox.version }} is available. Please download and install the new update.
              </div>

              <div class="d-flex flex-row justify-center mt-12">
                <app-update-btn
                    :product-version-prop="sandbox.version"
                    :ready-to-upgrade-prop="readyToUpdate"
                    :update-finished-prop="updateFinished"
                    :loading-prop="loading"
                    @update="updateLauncher"
                />

                <v-btn
                    v-if="!mandatory && !loading"
                    large
                    small
                    outlined
                    rounded
                    class="ml-3"
                    color="primary"
                    style="text-transform: unset !important;"
                    @click.prevent="redirect"
                >
                  Skip
                </v-btn>
              </div>
            </div>
          </transition>
        </div>

        <div v-else >
          <transition :key="$router.currentRoute.path" mode="out-in" name="scale">
            <div>
              <div class="d-flex flex-column align-center">

                <div class="text-h3 text--secondary d-flex justify-center">
                  Update available!
                </div>

                <div class="text--secondary mt-4 text-center ">
                  Version {{ sandbox.version }} is available.
                  Please copy and paste the provided URL into your browser. Note that the file will download
                  automatically to your local Downloads folder. Once downloaded, uninstall your previous launcher, and
                  then proceed to install the newly downloaded one.
                </div>

                <div v-for="(revitYear, index) in sandbox.allVersions" :key="`revitYear + ${index}`">
                  <div class="d-flex flex-row align-center mt-12">

                    <v-text-field
                        solo
                        flat
                        :value="revitYear.file.url"
                        full-width
                        class="input-ellipsis height-52"
                    >

                      <template #prepend-inner>
                        <div class="d-flex flex-row align-center mr-2">
                          <span>
                            Revit:
                          </span>

                          <span class="ml-1">
                            {{revitYear.version}}
                          </span>
                        </div>
                      </template>

                      <template #append>
                        <v-tooltip
                            color="transparent"
                            class="text-center"
                            nudge-top="5"
                            nudge-right="0"
                            top
                            :open-on-click="true"
                            :open-on-hover="false"
                            v-model="revitYear.isShowTooltip"
                        >
                      <span class="color-primary py-1 px-2" style="border-radius: 5px">
                        Copied to clipboard!
                      </span>

                          <template #activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                v-on="on"
                            >
                              <v-btn
                                  color="primary"
                                  icon
                                  small
                                  @click="copyToClipBoard(revitYear)"
                              >
                                <app-svg
                                    color="primary"
                                    src="@/assets/img/icons/copy.svg"
                                    size="28"
                                />
                              </v-btn>
                            </div>
                          </template>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </div>
                </div>

                <v-btn
                    v-if="!mandatory"
                    color="primary"
                    outlined
                    raised
                    large
                    rounded
                    style="text-transform: unset !important;"
                    @click.prevent="$router.push('/catalogues')"
                    class="mt-6 "
                >
                  Skip
                </v-btn>

              </div>
            </div>

          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppUpdateBtn from '@/components/app/AppUpdateBtn.vue'
import AppSvg from '@/components/app/AppSvg.vue'

import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'

import { CustomErrorClass } from '@/classes/error/CustomErrorClass'

import VueCookies from 'vue-cookies'

export default {
  name: 'UpdateView',
  components: { AppUpdateBtn , AppSvg },
  data () {
    return {

      readyToUpdate: true,

      updateFinished: false,

      loading: false,

      actionStateConstants: actionStates,
      showUrl: false,

      isShowTooltip: false

    }
  },

  computed: {
    ...mapGetters(
        {
          showUpdateView: 'product/getShowUpdateView',
          sandbox: 'product/getSandBox',
          currentState: 'product/getCurrentState',
        }
    ),

    isItOldLauncher () {
      if (window.jsSandboxInterface && window.jsSandboxInterface.install) {
        return false
      }
      return true
    },

    mandatory () {
      if (this.sandbox && this.sandbox._highestVersion) {
        return this.sandbox._highestVersion.mandatory_users
      }
      return false
    },

    userToken () {
      return VueCookies.get('current_user')
    },
  },

  watch: {
    currentState (newV, oldV) {
      if (oldV === actionStates.LOADING && newV === actionStates.DATA_LOADED && !this.showUpdateView) {
        this.redirect()
      }
    }
  },

  methods: {
    async fetchSandBox () {
      try {
        await this.$store.dispatch('product/fetchSandBox')
      } catch (e) {
        console.log(e)
      }
    },

    redirect () {
      this.$router.push('/catalogues')
    },

    copyToClipBoard (revitYearObj) {
      setTimeout(()=>revitYearObj.isShowTooltip = false, 2000)
      navigator.clipboard.writeText(this.sandbox.versionUrl)
    },

    async downloadFinished () {
      this.loading = false
      this.readyToUpdate = true

      await this.$store.dispatch('snackbar/showSnackbarMessage', {
        message: 'Please close Revit, in order to update Sandbox Plugin',
        duration: 4000,
        mode: 'warning'
      })
    },

    async errorInstall(error) {
      console.warn(error, 'the error')

      await this.$store.dispatch('snackbar/showSnackbarMessage', {
        message: 'Something went wrong, please contact system administrator',
        duration: 4000,
        mode: 'error'
      })

      this.loading = false
      this.readyToUpdate = true
    },

    async updateLauncher () {
      try {
        this.loading = true
        this.readyToUpdate = false
        const payload = {
          "AccessToken" : this.userToken,
          "DownloadLink" : this.sandbox.versionUrl
        }

        window.jsSandboxInterface.install(JSON.stringify(payload))

      } catch (e) {
        if (e instanceof CustomErrorClass) {
          await this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: `${e.message}`,
            duration: 4000,
            mode: 'error'
          })
        }

        else {
          await this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: `Something went wrong, please contact system administrator`,
            duration: 4000,
            mode: 'error'
          })
        }
        this.loading = false
        this.readyToUpdate = true

        console.log(e)
      }
    }
  },

  async created () {
    await this.fetchSandBox()

    window['downloadFinished'] = this.downloadFinished
    window['errorInstall'] = this.errorInstall

  },
}
</script>

<style lang="scss" scoped>
.update-btn-content-wrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  .dot {
    height: 10px;
    width: 10px;
    background-color: var(--v-success2-base);
    border-radius: 50%;
  }
}

.url-link {
  color: #0B57D0;
}

.color-primary {
  color: white;
  background-color: var(--v-primary-base);
}


</style>
