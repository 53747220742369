<template>
  <div class="d-flex justify-center flex-column align-center">
    <v-btn
        id="start-btn"
        :color="'var(--v-success2-base)'"
        min-width="100"
        outlined
        :small="smallBtnProp"
        :large="isLargeBtn"
        raised
        rounded
        style="text-transform: unset !important; text-align: center"
        @click.stop.prevent="$emit('update')"
    >
      <div class="update-btn-content-wrapper">

        <div v-if="readyToUpgradeProp">
          <span>Update</span>
        </div>

        <div v-else-if="loadingProp">
          <v-progress-circular
              size="16"
              width="1"
              indeterminate
              color="'var(--v-success2-base)'"
          ></v-progress-circular>

          Upgrading
        </div>

        <div v-else-if="updateFinishedProp" >
          <div class="d-flex flex-row justify-space-between align-center">

            <span style="padding-top: 3px">&#10003;</span>
            <span class="pl-1"> Updated</span>

          </div>
        </div>
      </div>

    </v-btn>
  </div>
</template>


<script>
export default {
  name: 'UpdatingBtn',

  props: {
    loadingProp: {
      type: Boolean,
      required: true
    },

    readyToUpgradeProp: {
      type: Boolean,
      required: true
    },

    updateFinishedProp: {
      type: Boolean,
      required: true
    },

    smallBtnProp: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isLargeBtn () {
      return !this.smallBtnProp
    }
  }

}
</script>


<style scoped lang="scss">

</style>