import { actionStates } from '@/constants'
import OrganizationsService from '@/services/оrganizations.service'
import AuthService from '@/services/auth.service'

import ProductService from '@/services/product.service'

const state = {
  currentState: actionStates.INITIAL,
  userSelectedOrganization: null,
  allUserOrganizations: [],
  organizationSelectedFeatures: null
}

const getters = {
  getCurrentState (state) {
    return state.currentState
  },

  getUserSelectedOrganization (state) {
    return state.userSelectedOrganization
  },

  getAllUserOrganizations (state) {
    return state.allUserOrganizations
  },

  getOrganizationSelectedFeatures (state) {
    return state.organizationSelectedFeatures
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setUserSelectedOrganization (state, userSelectedOrganization) {
    if (userSelectedOrganization) {
      state.userSelectedOrganization = userSelectedOrganization
      return
    }

    state.userSelectedOrganization = null
  },

  setAllUserOrganizations (state, allUserOrganizations) {
    if (allUserOrganizations) {
      state.allUserOrganizations = allUserOrganizations
      return
    }

    state.allUserOrganizations = null
  },

  setOrganizationSelectedFeatures (state, organizationSelectedFeatures) {
    state.organizationSelectedFeatures = organizationSelectedFeatures
  }
}

const actions = {
  async fetchAllOrganizations (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let organizations = await OrganizationsService.getUserProductOrganizations('b68c5d1e-5d77-4b37-83c9-6c864dc12948')

      vuexContext.commit('setAllUserOrganizations', organizations)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async updateUserSelectedOrganization (vuexContext, data) {
    vuexContext.commit('setUserSelectedOrganization', data)
  },

  async fetchUserOrganizationIfMissing (vuexContext, slug) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let selectedOrganization = vuexContext.getters.getUserSelectedOrganization

      if (!(selectedOrganization && selectedOrganization.id)) {
        let organization = await AuthService.getOrganization(slug)
        await vuexContext.dispatch('updateUserSelectedOrganization', organization)

        const sandBox = await ProductService.getSandboxProduct()

        const organizationSelectedFeatures = await OrganizationsService.getOrganizationSelectedFeatures({
          productId:sandBox.id,
          slug
        })

        await vuexContext.commit('setOrganizationSelectedFeatures', organizationSelectedFeatures)

        vuexContext.commit('setActionState', actionStates.DATA_LOADED)
      }
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  }

}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
